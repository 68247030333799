export default defineNuxtRouteMiddleware(() => {
  if (process.server) {
    return;
  }

  const basketStore = useBasketStore();

  if (!basketStore.basket?.orderLines?.length) {
    return abortNavigation();
  }

  return true;
});
